<template>
  <div class="brief-page">
    <!-- 商会简介 -->
    <MainNav />

    <div class="bannerTabbar">
      <img src="../../assets/abstractBanner.png" alt="" />
    </div>

    <div class="introduced-wrapper">
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        "
      >
        <div class="commerceText" style="">
          <span style="color: #fff">简介</span
          >广东省湖南怀化商会成立于2012年11月，是由湖南怀化籍在粤人士投资的工商企业自愿发起组成、经怀化市人民政府同意、由广东省社团登记机关登记注册的地方性、非营利性社会组织
        </div>
      </div>
      <!-- 商会宗旨 -->
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        "
      >
        <div class="title">
          <div class="abstractClass">
            <img
              class="abstractLeft"
              src="../../assets/architecture/architecture01.png"
              alt=""
            />
            <span style="color: #681107; margin: 0 0.5rem; font-weight: 600"
              >商会宗旨</span
            >
            <img
              class="abstractRight"
              src="../../assets/architecture/architecture02.png"
              alt=""
            />
          </div>
          <div class="grand">怀纳天下、团结互助、</div>
          <div class="grand">化育桑梓、奋发图强。</div>
        </div>
      </div>

      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        "
      >
        <div class="commerceText address">
          <span style="color: #fff">简介</span
          >商会办公地址：广东省深圳市南山区高新技术园北区朗山路16号华翰科技园C座308。
        </div>
      </div>

      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        "
      >
        <div class="textCenter">
          <div style="margin: 1rem 0">
            怀化市位于湖南省西部，自古就有“黔滇门户”、“全楚咽喉”之称，是我国中东部地区通向大西南的重要交通枢纽城市，有湖南西大门之美誉。优越的区位，便捷的交通，让怀化成为湘鄂渝黔桂五省区周边重要的人才聚集、物资流动、信息交流的经济文化中心。怀化市是湖南省面积最大的地级市，下辖十三个县（市区），总面积2.76万平方公里，人口500多万。资源丰富，发展潜力巨大，后发优势明显，是国务院和湖南省重点扶持开发的中西部地区之一。怀化市又是湖南省劳务输出的重点城市之一，每年到珠三角和长三角的务工人员上百万人，其中在广东省境内的务工人员达几十万人。经过多年打拼，已孕育出一批有实力的企业家，其中资产在千万以上的企业上百家，有的已发展成为拥有资产几十亿的大型骨干企业。
          </div>
          <div>
            <span style="color: #fff">简介</span
            >为了团结凝聚怀化籍在粤经商经营人士，共同打拼、抱团取暖，践行“怀纳天下，团结互助，化育桑梓，奋发图强”的宗旨，为在粤企业和经营人员建立资源共享、信息互通、互利互惠的平台，经怀化市人民政府积极推动，广东省民政厅批准，于二〇一二年十一月正式注册成立了广东省湖南怀化商会。
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        "
      >
        <div class="head">商会发起企业及负责人：</div>
      </div>
      <!-- 表格 -->
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        "
      >
        <table class="Modbus">
          <tr>
            <td class="company">深圳华瀚科技有限公司</td>
            <td class="position">董事长</td>
            <td class="compellation">郑能欢</td>
          </tr>
          <tr>
            <td class="company">广州金升阳科技有限公司</td>
            <td class="position">董事长</td>
            <td class="compellation">尹向阳</td>
          </tr>
          <tr>
            <td class="company">广东中人建设工程有限公司</td>
            <td class="position">董事长</td>
            <td class="compellation">贺启宝</td>
          </tr>
          <tr>
            <td class="company">奥玛健身器材制造有限公司</td>
            <td class="position">董事长</td>
            <td class="compellation">邢开斌</td>
          </tr>
          <tr>
            <td class="company">广州瑞鑫贸易发展有限公司</td>
            <td class="position">董事长</td>
            <td class="compellation">张&#8195;斌</td>
          </tr>
          <tr>
            <td class="company">深圳恒发顺纸制造工艺有限公司</td>
            <td class="position">董事长</td>
            <td class="compellation">黄志成</td>
          </tr>
          <tr>
            <td class="company">广东华协律师事务所</td>
            <td class="position">主 任</td>
            <td class="compellation">仇秀斌</td>
          </tr>
          <tr>
            <td class="company">深圳市碧桐湾健康实业发展有限公司</td>
            <td class="position">董事长</td>
            <td class="compellation">杨&#8195;敏</td>
          </tr>
        </table>
      </div>

      <!-- 组织结构 -->
      <!-- <div>
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
          "
        >
          <div class="architecture">
            <img
              class="abstractLeft"
              src="../../assets/architecture/architecture01.png"
              alt=""
            />
            <span style="color: #681107; font-weight: 600; margin: 0 1rem"
              >商会组织架构</span
            >
            <img
              class="abstractRight"
              src="../../assets/architecture/architecture02.png"
              alt=""
            />
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
          "
        >
          <div class="textCenter">
            <img
              class="abstractLeft"
              src="../../assets/architecture/architecture03.jpg"
              alt=""
            />
          </div>
        </div>
      </div> -->
      <!-- 联系我们 -->
      <div class="contact-us">
        <p>联系我们</p>
        <div class="contact-us-content">
          <div class="map-container">
            <amap :zoom="18" :center="[113.944837, 22.557726]">
              <amap-marker
                :position="[113.944837, 22.557726]"
                :label="{
                  content: '广东省湖南怀化商会',
                  direction: 'bottom',
                }"
                draggable
              />
              <!-- <amap-info-window
                  :visible="showInfoWindow"
                  :position="address.position"
                  auto-move
                  is-custom
                >
                  <a-card>
                    <a-icon
                      type="close"
                      @click="hideInfoWindow"
                    />
                    <h3>{{ 广东省湖南怀化商会 }}</h3>
                    <p>
                      <label>地址：广东省深圳市南山区高新技术园北区朗山路16号华翰科技园A座201</label>
                      <span>{{ shop.address }}</span>
                    </p>
                    <my-gallery :data="shop.photos" />
                  </a-card>
                </amap-info-window> -->
            </amap>
          </div>
          <div class="oficeAddress">
            <div style="font-size: 2.2rem; margin-bottom: 2rem">
              商会办公地址:
            </div>
            <p>广东省深圳市南山区高新技术园北区朗山路16号华翰科技园A座201</p>
            <p>（导航：广东省湖南怀化商会）</p>
            <p>联系人：专职副秘书长 戴云英</p>
            <p>联系电话：181 2631 6999</p>
            <p>工作邮箱：gdhhsh@163.com</p>
            <img
              style="width: 10rem; height: 10rem"
              src="../../assets/email.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <rightNav />
    <Footer />
  </div>
</template>
<script>
import MainNav from "../../components/MainNav/index";
import Footer from "../../components/Footer.vue";
import rightNav from "../../components/RightNav/RightNav";

export default {
  name: "Brief",
  data() {
    return {
      showInfoWindow: true,
      address: [113.944837, 22.557726],
      // features:[{"lnglat":{"Q":22.557725911012902,"R":113.94483720302583},"offset":{"x":-9,"y":-31},"type":"Marker"}],
    };
  },
  components: {
    MainNav,
    Footer,
    rightNav,
  },
  mounted() {},
  methods: {
    hideInfoWindow() {
      this.showInfoWindow = this.showInfoWindow == true ? false : true;
    },
  },
};
</script>

<style scoped lang="less">
@import "../../styles/public";
@import "../../styles/state";

.brief-page {
  margin: 0;
  font: 13px/1.5 "Microsoft YaHei", "Helvetica Neue", "Sans-Serif";
  min-height: 960px;
  // min-width: 600px;
  .bannerTabbar {
    font-size: 1rem;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .introduced-wrapper {
    margin: 7rem auto;
    text-align: center;
    .commerceText {
      margin-bottom: 5rem;
      font-size: 2rem;
      width: 100rem;
      text-align: left;
    }
    .commerceText.address {
      margin: 2rem 0;
    }
    // 头部样式
    .title {
      width: 100rem;
      padding: 2.5rem;
      border: 2px solid #f7d580;
      border-radius: 1rem;
      font-size: 2.2rem;
      text-align: center;
      position: relative;
      .abstractClass {
        position: absolute;
        background-color: #fff;
        padding: 0 2rem;
        // margin: 0 2rem;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    // 内容文字
    .textCenter {
      width: 100rem;
      padding: 2.5rem;
      border: 2px solid #f7d580;
      border-radius: 1rem;
      font-size: 1.8rem;
      text-align: left;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .article {
      width: 90rem;
      border: 1px solid #f32e4c;
      font-size: 1.6rem;
      padding: 4rem 2rem;
      margin-top: 8rem;
      text-align: left;
      position: relative;
      .chapter {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
      .line {
        margin-bottom: 4rem;
        p {
          margin: 0;
          height: 0.6rem;
          color: #f32e4c;
        }
      }
      .text {
        font-size: 1.8rem;
        line-height: 3rem;
        margin: 0.5rem 0;
      }
    }

    // 商会发起企业及负责人
    .head {
      width: 100rem;
      text-align: left;
      font-size: 1.8rem;
      margin: 6rem 0 1rem;
    }

    // 表格
    .Modbus {
      width: 100rem;
      font-size: 1.8rem;
      border: solid #ccc 1px; /*设置表格外边框*/
      border-radius: 10px; /*设置表格外边框圆角*/
    }

    .Modbus td,
    .Modbus th {
      border-right: solid #ccc 1px; /*设置表格单元格外边框，只设置右下边框*/
      border-bottom: solid #ccc 1px;
      padding: 10px; /*设置单元格内边距，单元格内容显得不拥挤*/
      text-align: left; /*单元格内容居中*/
    }
    .Modbus td:last-child,
    .Modbus th:last-child {
      border-right: none; /*去掉表格每一行最后一列的右边框*/
    }

    .Modbus tr:last-child td {
      border-bottom: none; /*去掉最后一行中所有单元格的下边框*/
    }
    /*鼠标移动至单元格，单元格高亮显示*/
    .Modbus tr:hover {
      background: #fbf8e9;
    }
    /*分别设置每一行中单元格的宽度*/
    .company {
      width: 70%;
    }
    .position {
      width: 15%;
    }
    .compellation {
      width: 15%;
    }

    // 组织架构
    .architecture {
      width: 100rem;
      text-align: left;
      margin: 4rem 0 1rem;
      font-size: 2.2rem;
    }

    @media screen and (min-width: 768px) {
      //pc
      .oficeAddress {
        width: 50%;
        height: 43rem;
        margin-top: 5rem;
        padding: 3rem;
        border: 2px solid #681107;
        font-size: 1.8rem;
        text-align: center;
      }
    }
    @media screen and (min-width: 200px) and (max-width: 768px) {
      //手机
      .oficeAddress {
        width: 90%;
        height: 43rem;
        margin-top: 5rem;
        padding: 3rem;
        border: 2px solid #681107;
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }

  .contact-us {
    max-width: 1160px;
    margin: 40px auto;
    & > p {
      font-size: 24px;
    }
    .contact-us-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .map-container {
        overflow: hidden;
        width: 100%;
        height: 400px;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  // 商会简介
  .brief-page {
    .introduced-wrapper {
      .commerceText,
      .title,
      .textCenter,
      .head,
      .Modbus,
      .architecture,
      .contactUs {
        width: 75rem;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  // 商会简介
  .brief-page {
    .introduced-wrapper {
      .commerceText,
      .title,
      .textCenter,
      .head,
      .Modbus,
      .architecture,
      .contactUs {
        width: 55rem;
      }
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 768px) {
  // 商会简介
  .brief-page {
    .introduced-wrapper {
      .commerceText,
      .title,
      .textCenter,
      .head,
      .architecture,
      .contactUs {
        width: 40rem;
        font-size: 1.6rem;
      }
      .Modbus {
        width: 40rem;
        font-size: 1.4rem;

        .company {
          width: 64%;
        }
        .position {
          width: 18%;
        }
        .compellation {
          width: 18%;
        }
      }
      .title {
        .abstractClass {
          img {
            width: 3.5rem;
          }
        }
      }
      .architecture {
        img {
          width: 3.5rem;
        }
      }
    }
  }
}
</style>
